<template>
    <el-dialog
        :title="$t('calendar.smart_suggestions')"
        custom-class="dialog-smart-calendar"
        :visible.sync="modalSmartCalendar"
        :close-on-click-modal="false"
        :close-on-press-escape="!$wait.is('adding')"
        :show-close="!$wait.is('adding')"
        width="98%"
        top="10px"
        @closed="$emit('closed')"
    >
        <div class="body">
            <div class="grid grid-cols-12 gap-6">
                <Map />
                <Settings @search="search" />
            </div>
            <TimeLine :chosenDate="chosenDate" :duration="duration" :addressUuid="addressUuid" :coords="coords" />
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="$emit('close')">{{ $t('common.close') }}</el-button>
        </span>
    </el-dialog>
</template>
<script>
export default {
    components: {
        Map:      () => import(/* webpackChunkName: "ModalSmartCalendar/Map" */ './components/Map'),
        TimeLine: () => import(/* webpackChunkName: "ModalSmartCalendar/TimeLine" */ './components/TimeLine'),
        Settings: () => import(/* webpackChunkName: "ModalSmartCalendar/Settings" */ './components/Settings'),
    },

    props: {
        modalSmartCalendar: {
            type:    Boolean,
            default: false,
        },
    },

    data() {
        return {
            chosenDate:  this.$dayjs(),
            duration:    0,
            addressUuid: null,
            coords:      {},
        };
    },

    methods: {
        search(event) {
            this.chosenDate = event.chosenDate;
            this.duration = event.duration;
            this.addressUuid = event.addressUuid;
            this.coords = event.coords;
        },
    },
};
</script>
<style>
.dialog-smart-calendar {
    min-height: 98vh;
    margin-bottom: 10px;
}
</style>
